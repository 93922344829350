import React from "react";
import "./Partners.css";
// import investorsdesktop from "../../img/Invester Logos- Desktop.svg";
// import investorsmobile from "../../img/Invester Logos- Mobile.svg";

// import partnersdesk from "../../img/partners_desktop.svg"
// import partnersmob from "../../img/partners_mob.svg"

import partnersdesk from "../../img/borderless.svg"
import partnersmob from "../../img/borderlessmobile.svg"

const Partners = () => {
  return (
    <div className="partners-wrapper">
      <h2 className="title">Our Partners</h2>
      <img
        className="partners-logos-desktop"
        alt="partners-logos"
        src={partnersdesk}
      />
      <img
        className="partners-logos-mobile"
        alt="partners-logos"
        src={partnersmob}
      />
    </div>
  );
};

export default Partners;
